<template>
  <div v-loading="loading">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <!-- {{ mcxStockList }} -->
      <!-- {{nseStockList}}
           {{optStockList}} -->

      <el-form
        :model="formData"
        :rules="formRules"
        ref="refForm"
        label-position="top"
        :status-icon="true"
      >
      <!-- {{dialogType}} -->
        <el-tabs class="marketwatch_tab" type="border-card">
          <el-tab-pane label="MCX" v-if="allow_mcx_trade">
            <el-row class="master-script">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item
                  :error="formErrors.script"
                  label=""
                  prop="script"
                  :label-width="formLabelWidth"
                >
                  <br />
                  <el-checkbox-group v-model="formData.mcx_script">


                  <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" v-for="(script, index) in mcxStockList" :key="index">
                    <el-checkbox
                      :label="script.stock_id"
                      >{{ script.name }}</el-checkbox
                    >
                  </el-col>


            
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="NSE" v-if="allow_nse_trade">
            <el-row class="master-script">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item
                  :error="formErrors.script"
                  label=""
                  prop="script"
                  :label-width="formLabelWidth"
                >
                  <br />

                  

                  <el-checkbox-group v-model="formData.nse_script"  >
                    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" v-for="(script, index) in nseStockList" :key="index">
                      <el-checkbox
                        :label="script.stock_id"
                        >{{ script.name }}</el-checkbox
                      >
                    </el-col>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="OPT" v-if="allow_opt_trade">
            <el-row class="master-script">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item
                  :error="formErrors.script"
                  label=""
                  prop="script"
                  :label-width="formLabelWidth"
                >
                  <br />
                  <el-checkbox-group v-model="formData.opt_script">

                
                   <el-checkbox
                      v-for="(script, index) in optStockList"
                      :key="index"
                      :label="script.stock_id"
                      >{{ script.name }}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="CRYPTO" v-if="allow_crypto_trade">
            <el-row class="master-script">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item
                  :error="formErrors.script"
                  label=""
                  prop="script"
                  :label-width="formLabelWidth"
                >
                  <br />
                  <el-checkbox-group v-model="formData.crypto_script">
                    <el-checkbox
                      v-for="(script, index) in cryptoStockList"
                      :key="index"
                      :label="script.stock_id"
                      >{{ script.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="FOREX"  v-if="allow_forex_trade">
            <el-row class="master-script">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item
                  :error="formErrors.script"
                  label=""
                  prop="script"
                  :label-width="formLabelWidth"
                >
                  <br />
                  <el-checkbox-group v-model="formData.forex_script">
                    <el-checkbox
                      v-for="(script, index) in forexStockList"
                      :key="index"
                      :label="script.stock_id"
                      >{{ script.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="handleCloseDialog()"> Cancel </el-button> -->
        <!-- <el-button type="primary" @click="sendChildFormData()">Save</el-button> -->
        <el-button @click="handleCloseDialog()" type="primary" >Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { requiredRule } from "@/rules/all-rules";
export default {
  name: "AddEditDialog",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    allow_mcx_trade: {
      type: Boolean,
      default: false,
    },
    allow_nse_trade: {
      type: Boolean,
      default: false,
    },
    allow_opt_trade: {
      type: Boolean,
      default: false,
    },
    allow_forex_trade: {
      type: Boolean,
      default: false,
    },
    allow_crypto_trade: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: function () {
        return {
        //   mcx_script: [],
        //   nse_script: [],
        //   opt_script: [],
      };
      },
    },
    mcxStockList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    nseStockList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    optStockList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    cryptoStockList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    forexStockList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formRules: {},
      formLabelWidth: "120px",
    };
  },
  computed: {
    dialogTitle() {
      return this.dialogType === "create" ? "Block Script (un-checke for block script)" : "Block Script (un-checke for block script)";
    },
  },
  created() {
    /**
     * Including  Of Validation Rule
     */
    this.formRules = {
      newPassword: requiredRule("newPassword"),
    };
  },
  methods: {
    handleCloseDialog() {
      this.$emit("childClose");
    },
    sendChildFormData() {
      this.loading = true;
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          // WHEN EDIT MERGE NEW PARAMETERS
          if (this.dialogType === "edit") {
            this.formData = Object.assign(this.formData, {
              dialog_type: this.dialogType,
            });
          }
          console.log(this.formData.newPassword);
          this.$emit("getChildFormData", this.formData.newPassword);
          this.loading = false;
        }
      });
    },
  },
};
</script>
