<template>
  <div
    class="menu_wrapper_container box-shadow trading-container"
    v-loading="loading"
  >
    <div class="trading-client">
      <el-form
        :model="formData"
        :rules="formRules"
        ref="refForm"
        label-position="top"
        :status-icon="true"
      >
        <!-- Personal Details -->
        <el-card class="user_add_wrapper">
          <div>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                <h3>Personal Details:</h3>
              </el-col>
            </el-row>

            <el-row :gutter="20" class="trading-client">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.name"
                  label="Name"
                  prop="name"
                  :label-width="formLabelWidth"
                >
                  <el-input v-model="formData.name" autocomplete="off"></el-input>
                  <!-- <el-input v-model="formData.id" autocomplete="off" hidden></el-input> -->
                  <input v-model="formData.id" autocomplete="off" hidden />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.mobile"
                  label="Mobile"
                  prop="mobile"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="formData.mobile"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.username"
                  label="Username"
                  prop="username"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="formData.username"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  label="Credit Limit"
                  prop="credit_limit"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="formData.credit_limit"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.city"
                  label="City"
                  prop="city"
                  :label-width="formLabelWidth"
                >
                  <el-input v-model="formData.city" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.password"
                  label="Password"
                  prop="password"
                  :label-width="formLabelWidth"
                >
                  <!-- <el-input
                    v-model="formData.password"
                    autocomplete="off"
                  ></el-input> -->
                  <el-input
                    v-model="formData.password"
                    type="password"
                    autocomplete="off"
                    show-password
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <br>

        <!-- Config  -->
        <el-card class="user_add_wrapper">
          <div>
            <el-row>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                <h3>Config:</h3>
              </el-col>
            </el-row>

            <el-row :gutter="10" class="trading-client">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.is_demo"
                  label=""
                  prop="is_demo"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="config.config_is_demo"
                    >Demo account?</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.config_allow_orders_current_bid_ask_price"
                  label=""
                  prop="config_allow_orders_current_bid_ask_price"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox
                    v-model="config.config_allow_orders_current_bid_ask_price"
                    >Allow orders at current Bid / Ask price</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.pending_order_tigger_ltp"
                  label=""
                  prop="pending_order_tigger_ltp"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox
                    v-model="config.pending_order_tigger_ltp"
                    >Execute Pending Orders At Ltp</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="
                    formErrors.config_allow_fresh_entry_order_above_high_and_below_low
                  "
                  label=""
                  prop="config_allow_fresh_entry_order_above_high_and_below_low"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox
                    v-model="
                      config.config_allow_fresh_entry_order_above_high_and_below_low
                    "
                    >Allow Fresh Entry Order above high & below low?</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="
                    formErrors.config_one_side_brokerage_for_intraday_trades_Higher_side
                  "
                  label=""
                  prop="config_one_side_brokerage_for_intraday_trades_Higher_side"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox
                    v-model="
                      config.config_one_side_brokerage_for_intraday_trades_Higher_side
                    "
                    >One Side brokerage for intraday trades (Higher
                    side)</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="
                    formErrors.config_auto_close_trades_if_losses_cross_beyond_configured_limit
                  "
                  label=""
                  prop="config_auto_close_trades_if_losses_cross_beyond_configured_limit"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox
                    v-model="
                      config.config_auto_close_trades_if_losses_cross_beyond_configured_limit
                    "
                    >Auto-close trades if losses cross beyond the configured
                    limit</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="
                    formErrors.config_auto_close_trades_if_insufficient_fund_to_hold_overnight
                  "
                  label=""
                  prop="config_auto_close_trades_if_insufficient_fund_to_hold_overnight"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox
                    v-model="
                      config.config_auto_close_trades_if_insufficient_fund_to_hold_overnight
                    "
                    >Auto-close trades if insufficient fund to hold
                    overnight</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.config_allow_orders_between_high_low"
                  label=""
                  prop="config_allow_orders_between_high_low"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox
                    v-model="config.config_allow_orders_between_high_low"
                    >Allow Orders between High - Low?</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.config_allow_limit_orders_between_high_low"
                  label=""
                  prop="config_allow_limit_orders_between_high_low"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox
                    v-model="config.config_allow_limit_orders_between_high_low"
                    >Allow Limit order between high low?</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="
                    formErrors.config_trigger_orders_at_new_high_low_rates_instead_bid_ask
                  "
                  label=""
                  prop="config_trigger_orders_at_new_high_low_rates_instead_bid_ask"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox
                    v-model="
                      config.config_trigger_orders_at_new_high_low_rates_instead_bid_ask
                    "
                    >Trigger orders at New High - Low rates Instead of
                    Bid-Ask?</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.config_trade_equity_as_units_instead_of_lots"
                  label=""
                  prop="config_trade_equity_as_units_instead_of_lots"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox @change="handleCheckedTradeEquityInsteedOfLots"
                    v-model="config.config_trade_equity_as_units_instead_of_lots"
                    >Trade equity as units instead of lots.</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.config_account_status"
                  label=""
                  prop="config_account_status"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="config.config_account_status"
                    >Account Status</el-checkbox
                  >
                </el-form-item>
              </el-col>
              
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.config_scalping"
                  label=""
                  prop="config_scalping"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox
                    v-model="config.config_scalping"
                    >Scalping not allowed</el-checkbox
                  >
                </el-form-item>
                <el-form-item v-if="config.config_scalping"
                  :error="formErrors.config_scalping_time"
                  label="Scalping Time (in minutes)"
                  prop="config_scalping_time"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="config.config_scalping_time"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
            
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="
                    formErrors.config_auto_close_all_active_trades_when_losses_reach_percent_ledger_balance
                  "
                  label=""
                  prop="config_auto_close_all_active_trades_when_losses_reach_percent_ledger_balance"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox
                    v-model="
                      config.config_auto_close_all_active_trades_when_losses_reach_percent_ledger_balance
                    "
                    >auto-Close all active trades when the losses reach % of
                    Ledger-balance</el-checkbox
                  >
                </el-form-item>
                <el-form-item v-if="config.config_auto_close_all_active_trades_when_losses_reach_percent_ledger_balance"
                  :error="formErrors.config_auto_close_all_active_trades_when_losses_reach_percent_ledger_balance_value"
                  label="Enter Value"
                  prop="config_auto_close_all_active_trades_when_losses_reach_percent_ledger_balance_value"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="config.config_auto_close_all_active_trades_when_losses_reach_percent_ledger_balance_value"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="
                    formErrors.config_notify_client_when_losses_reach_percent_ledger_balance
                  "
                  label=""
                  prop="config_notify_client_when_losses_reach_percent_ledger_balance"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox
                    v-model="
                      config.config_notify_client_when_losses_reach_percent_ledger_balance
                    "
                    >Notify client when the losses reach % of
                    Ledger-balance</el-checkbox
                  >
                </el-form-item>
                  <el-form-item v-if="config.config_notify_client_when_losses_reach_percent_ledger_balance"
                  :error="formErrors.config_notify_client_when_losses_reach_percent_ledger_balance_value"
                  label="Enter Value"
                  prop="config_notify_client_when_losses_reach_percent_ledger_balance_value"
                  :label-width="formLabelWidth"
                  >
                  <el-input
                    v-model="config.config_notify_client_when_losses_reach_percent_ledger_balance_value"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <br>

        <!-- MCX Futures  -->
        <el-card class="user_add_wrapper" v-if="allow_mcx_trade">
          <div>
            <el-row :gutter="20" class="trading-client">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                <h3>MCX Futures:</h3>

                
              </el-col>
            </el-row>
            <el-row :gutter="20" class="trading-client">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.mcx_trading"
                  label=""
                  prop="mcx_trading"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox @change="mcxReset" v-model="mcx.mcx_trading">MCX Trading</el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.mcx_per_script_qty_setting"
                  label=""
                  prop="mcx_per_script_qty_setting"
                  :label-width="formLabelWidth"
                  v-if="mcx.mcx_trading"
                >
                  <el-checkbox v-model="config.mcx_per_script_qty_setting"
                    >Per script qty setting</el-checkbox
                  >
                </el-form-item>
             
              </el-col>
              <div v-if="mcx.mcx_trading">
                
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.mcx_btst_start_time"
                    label="BTST start time"
                    prop="mcx_btst_start_time"
                    :label-width="formLabelWidth"
                  >
                    <!-- <el-input 
                      v-model="mcx.mcx_btst_start_time"
                      autocomplete="off"
                    ></el-input> -->
                    <span style="padding-left: 10px;"><vue-timepicker  v-model="mcx.btst_start_time" ></vue-timepicker></span>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.mcx_btst_end_time"
                    label="BTST end time"
                    prop="mcx_btst_end_time"
                    :label-width="formLabelWidth"
                  >
                    <!-- <el-input
                      v-model="mcx.mcx_btst_end_time"
                      autocomplete="off"
                    ></el-input> -->
                    <span style="padding-left: 10px;"><vue-timepicker  v-model="mcx.btst_end_time" ></vue-timepicker></span>

                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.mcx_minimum_lot_size_per_single_trade_mcx"
                    label="Minimum lot size required per single trade of MCX"
                    prop="mcx_minimum_lot_size_per_single_trade_mcx"
                    :label-width="formLabelWidth"
                  >
                    <el-input @blur="handleBlurMCXmcx_minimum_lot_size_per_single_trade_mcx"
                      v-model="mcx.mcx_minimum_lot_size_per_single_trade_mcx"
                      autocomplete="off"
                    ></el-input>
                    <small v-if="error_mcx_minimum_lot_size_per_single_trade_mcx" style="color:red;">Required Minimum lot size required per single trade of MCX</small>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="!config.mcx_per_script_qty_setting">
                  <el-form-item
                    :error="formErrors.mcx_maximum_lot_size_per_single_trade_mcx"
                    label="Maximum lot size allowed per single trade of MCX"
                    prop="mcx_maximum_lot_size_per_single_trade_mcx"
                    :label-width="formLabelWidth"
                  >
                    <el-input @blur="handleBlurMCXmcx_maximum_lot_size_per_single_trade_mcx"
                      v-model="mcx.mcx_maximum_lot_size_per_single_trade_mcx"
                      autocomplete="off"
                    ></el-input>
                    <small v-if="error_mcx_maximum_lot_size_per_single_trade_mcx" style="color:red;">Required Maximum lot size allowed per single trade of MCX</small>

                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="!config.mcx_per_script_qty_setting">
                  <el-form-item
                    :error="
                      formErrors.mcx_maximum_lot_size_per_script_mcx_actively_open_at_time
                    "
                    label="Maximum lot size allowed per script of MCX to be actively open at a time"
                    prop="mcx_maximum_lot_size_per_script_mcx_actively_open_at_time"
                    :label-width="formLabelWidth"
                  >
                    <el-input @blur="handleBlurMCXmcx_maximum_lot_size_per_script_mcx_actively_open_at_time"
                      v-model=" mcx.mcx_maximum_lot_size_per_script_mcx_actively_open_at_time"
                      autocomplete="off"
                    ></el-input>
                    <small v-if="error_mcx_maximum_lot_size_per_script_mcx_actively_open_at_time" style="color:red;">Required Maximum lot size allowed per script of MCX to be actively open at a time</small>

                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.max_size_all_commodity"
                    label="Max Size All Commodity"
                    prop="max_size_all_commodity"
                    :label-width="formLabelWidth"
                  >
                    <el-input @blur="handleBlurMCXmax_size_all_commodity"
                      v-model="mcx.max_size_all_commodity"
                      autocomplete="off"
                    ></el-input>
                    <small v-if="error_max_size_all_commodity" style="color:red;">Required Max Size All Commodity</small>

                  </el-form-item>
                </el-col>
                
                <div v-if="config.mcx_per_script_qty_setting">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-collapse v-model="activeNames" accordion class="stock_setting_accordion">
                      <el-collapse-item title="Qty setting" name="1">
                        <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                          <!-- <el-form-item
                            label="Select client"
                            prop=""
                            :label-width="formLabelWidth"
                            class="client_qty_list"
                          > -->
                            <el-select
                              v-model="formData.mcx_clent_qty"
                              clearable
                              filterable
                              placeholder="Select"
                              @change="handleMcxQtyList"
                            >
                            <el-option
                              v-for="(item, index) in mcx_client_list"
                                :key="index"
                                :label="item.username"
                                :value="index"
                            >
                            </el-option>                         
                            </el-select>
                          <!-- </el-form-item> -->
                        </el-col>


                        <table class="stock_setting_table">
                          <thead>
                            <th>Script</th>
                            <th>Max order lot</th>
                            <th>Max net lot</th>
                          </thead>
                          <tbody>
                            <tr v-for="(mcx, index) in mcx_per_script_qty_setting_list" :key="index" >
                            <td> {{mcx.name}}</td>
                            <td>  
                              <el-input
                                v-model="mcx_per_script_qty_setting_list[index].max_order_lot"
                                autocomplete="off"
                              ></el-input>
                            </td>
                            <td>   
                              <el-input
                                v-model="mcx_per_script_qty_setting_list[index].max_net_lot"
                                autocomplete="off"
                              ></el-input>
                            </td>
                            </tr>
                          </tbody>
                        </table>
                      </el-collapse-item>
                    </el-collapse>
                  </el-col>
                </div>


              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.mcx_brokerage_type"
                  label="Mcx Brokerage Type"
                  prop="mcx_brokerage_type"
                  :label-width="formLabelWidth"
                >
                  <el-select
                    v-model="mcx.mcx_brokerage_type"
                    clearable
                    filterable
                    placeholder="Select"
                    @blur="handleBlurMCXmcx_brokerage_type"
                  >
                    <el-option label="Per Crore Basis" value="per_core_basis"></el-option>
                    <el-option label="Per Lot Basis" value="per_lot_basis"></el-option>
                  </el-select>
                  <small v-if="error_mcx_brokerage_type" style="color:red;">Required Mcx Brokerage Type</small>

                </el-form-item>
              </el-col>
              <el-col v-if="mcx.mcx_brokerage_type == 'per_core_basis'" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.mcx_brokerage_per_crore"
                  label="MCX brokerage per crore"
                  prop="mcx_brokerage_per_crore"
                  :label-width="formLabelWidth"
                >
                  <el-input @blur="handleBlurMCXmcx_brokerage_per_crore"
                    v-model="mcx.mcx_brokerage_per_crore"
                    autocomplete="off"
                  ></el-input>
                  <small v-if="error_mcx_brokerage_per_crore" style="color:red;">Required MCX brokerage per crore</small>

                </el-form-item>
              </el-col>
              <!-- {{mcx_brokerage_lot_wise}} -->
                <div v-if="mcx.mcx_brokerage_type == 'per_lot_basis'">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="left">
                    <span style="text-align:left;color:#fff;">MCX Brokerage Lot wise:</span>
                    <el-button type="primary" size="small"  @click="appliesToAllBrokerageMCX()">applies to all</el-button>
                  </el-col>
                  <el-col v-for="(mcx, index) in mcx_brokerage_lot_wise" :key="index" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item
                      :label="mcx.name"
                    >
                      <el-input
                        v-model="mcx_brokerage_lot_wise[index].value"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  
                </div>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.mcx_exposure_mcx_type"
                  label="Exposure Mcx Type"
                  prop="mcx_exposure_mcx_type"
                  :label-width="formLabelWidth"
                >
                  <el-select
                    v-model="mcx.mcx_exposure_mcx_type"
                    clearable
                    filterable
                    placeholder="Select"
                  >
                    <el-option
                      label="Per Turnover Basis"
                      value="per_turnover_basis"
                    >
                    </el-option>
                    <el-option label="Per Lot Basis" value="per_lot_basis">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <div v-if="mcx.mcx_exposure_mcx_type == 'per_turnover_basis'">
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.mcx_intraday_exposure_margin_mcx"
                  label="Intraday Exposure/Margin MCX"
                  prop="mcx_intraday_exposure_margin_mcx"
                  :label-width="formLabelWidth"
                >
                  <el-input @blur="handleBlurMCXmcx_intraday_exposure_margin_mcx"
                    v-model="mcx.mcx_intraday_exposure_margin_mcx"
                    autocomplete="off"
                  ></el-input>
                  <small v-if="error_mcx_intraday_exposure_margin_mcx" style="color:red;">Required Intraday Exposure/Margin MCX</small>

                </el-form-item>
                <small style="text-align:left;color:#fff;">
                  Exposure auto calculates the margin money required for any new trade entry. Calculation : turnover of a trade devided by Exposure is required margin. eg. if gold having lotsize of 100 is trading @ 45000 and exposure is 200, (45000 X 100) / 200 = 22500 is required to initiate the trade.
                </small>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.mcx_holding_exposure_margin_mcx"
                  label="Holding Exposure/Margin MCX"
                  prop="mcx_holding_exposure_margin_mcx"
                  :label-width="formLabelWidth"
                >
                  <el-input @blur="handleBlurMCXmcx_holding_exposure_margin_mcx"
                    v-model="mcx.mcx_holding_exposure_margin_mcx"
                    autocomplete="off"
                  ></el-input>
                  <small v-if="error_mcx_holding_exposure_margin_mcx" style="color:red;">Required Holding Exposure/Margin MCX</small>

                </el-form-item>
                <small style="text-align:left;color:#fff;">
                  Holding Exposure auto calculates the margin money required to hold a position overnight for the next market working day. Calculation : turnover of a trade devided by Exposure is required margin. eg. if gold having lotsize of 100 is trading @ 45000 and holding exposure is 800, (45000 X 100) / 80 = 56250 is required to hold position overnight. System automatically checks at a given time around market closure to check and close all trades if margin(M2M) insufficient.
                </small>
              </el-col>
              </div>
              <div v-if="mcx.mcx_exposure_mcx_type == 'per_lot_basis'">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="left">
                  <span style="text-align:left;color:#fff;">Intraday + Holding Exposure Value:</span>
                    <el-button type="primary"  size="small" @click="appliesToAllExposureMCX()">applies to all</el-button>
                </el-col>
                <el-col v-for="(mcx, index) in mcx_exposure_stock_list" :key="index" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :label="mcx.name"
                  >
                  <br>
                  <div style="text-align:left;color:#fff;" class="sub-title">Intraday Exposure Value</div>
                    <el-input v-model="mcx_exposure_stock_list[index].intraday_value"
                      autocomplete="off"
                      placeholder="Intraday value"
                    ></el-input>
                    <div style="text-align:left;color:#fff;" class="sub-title"> Holding Exposure Value</div>
                    <el-input
                      v-model="mcx_exposure_stock_list[index].holding_value"
                      autocomplete="off"
                      placeholder="Holding value"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </div>
              </div>
            </el-row>
          </div>
        </el-card>
        <br>

        <!-- Equity Futures  -->
        <el-card class="user_add_wrapper" v-if="allow_nse_trade">
          <div>
            <el-row :gutter="20" class="trading-client">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                <h3>Equity Futures / Others :</h3>
              </el-col>
            </el-row>

            <el-row :gutter="20" class="trading-client">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.equity_trading"
                  label=""
                  prop="equity_trading"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox @change="nseReset" v-model="equity.equity_trading"
                    >Equity Trading</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.nse_per_script_qty_setting"
                  label=""
                  prop="nse_per_script_qty_setting"
                  :label-width="formLabelWidth"
                  v-if="equity.equity_trading"
                >
                  <el-checkbox v-model="config.nse_per_script_qty_setting"
                    >Per script qty setting</el-checkbox
                  >
                </el-form-item>
              </el-col>
              <div v-if="equity.equity_trading">
             
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.equity_btst_start_time"
                    label="BTST start time"
                    prop="equity_btst_start_time"
                    :label-width="formLabelWidth"
                  >
                    <span style="padding-left: 10px;"><vue-timepicker  v-model="equity.btst_start_time" ></vue-timepicker></span>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.equity_btst_end_time"
                    label="BTST end time"
                    prop="equity_btst_end_time"
                    :label-width="formLabelWidth"
                  >
                    <span style="padding-left: 10px;"><vue-timepicker  v-model="equity.btst_end_time" ></vue-timepicker></span>
                  </el-form-item>
                </el-col>
              
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="
                    formErrors.equity_minimum_lot_size_per_single_trade_equity_index
                  "
                  label="Minimum lot size required per single trade of Equity INDEX"
                  prop="equity_minimum_lot_size_per_single_trade_equity_index"
                  :label-width="formLabelWidth"
                >
                  <el-input :disabled="inputDisabled" @blur="handleBlurNSEequity_minimum_lot_size_per_single_trade_equity_index"
                    v-model="
                      equity.equity_minimum_lot_size_per_single_trade_equity_index
                    "
                    autocomplete="off"
                  ></el-input>
                  <small v-if="error_equity_minimum_lot_size_per_single_trade_equity_index" style="color:red;">Required Minimum lot size required per single trade of Equity INDEX</small>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="!config.nse_per_script_qty_setting">
                <el-form-item
                  :error="
                    formErrors.equity_maximum_lot_size_per_single_trade_equity_index
                  "
                  label="Maximum lot size allowed per single trade of Equity INDEX"
                  prop="equity_maximum_lot_size_per_single_trade_equity_index"
                  :label-width="formLabelWidth"
                >
                  <el-input @blur="handleBlurNSEequity_maximum_lot_size_per_single_trade_equity_index"
                    v-model="equity.equity_maximum_lot_size_per_single_trade_equity_index"
                    autocomplete="off"
                  ></el-input>
                  <small v-if="error_equity_maximum_lot_size_per_single_trade_equity_index" style="color:red;">Required Maximum lot size allowed per single trade of Equity INDEX</small>

                </el-form-item>
              </el-col>
              
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="!config.nse_per_script_qty_setting">
                <el-form-item
                  :error="
                    formErrors.equity_maximum_lot_size_per_script_equity_index_actively_open_at_time
                  "
                  label="Maximum lot size allowed per script of Equity INDEX to be actively open at a time"
                  prop="equity_maximum_lot_size_per_script_equity_index_actively_open_at_time"
                  :label-width="formLabelWidth"
                >
                  <el-input @blur="handleBlurNSEequity_maximum_lot_size_per_script_equity_index_actively_open_at_time"
                    v-model="equity.equity_maximum_lot_size_per_script_equity_index_actively_open_at_time"
                    autocomplete="off"
                  ></el-input>
                  <small v-if="error_equity_maximum_lot_size_per_script_equity_index_actively_open_at_time" style="color:red;">Maximum lot size allowed per script of Equity INDEX to be actively open at a time</small>

                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.equity_max_size_all_equity"
                  label="Max Size All Equity"
                  prop="equity_max_size_all_equity"
                  :label-width="formLabelWidth"
                >
                  <el-input @blur="handleBlurNSEequity_max_size_all_equity"
                    v-model="equity.equity_max_size_all_equity"
                    autocomplete="off"
                  ></el-input>
                  <small v-if="error_equity_max_size_all_equity" style="color:red;">Required Max Size All Equity</small>

                </el-form-item>
              </el-col>
              <div v-if="config.nse_per_script_qty_setting">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-collapse v-model="activeNamesNse" accordion class="stock_setting_accordion">
                      <el-collapse-item title="Qty setting" name="1">
                         <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                          <!-- <el-form-item
                            label="Select client"
                            prop=""
                            :label-width="formLabelWidth"
                            class="client_qty_list"
                          > -->
                            <el-select
                              v-model="formData.nse_clent_qty"
                              clearable
                              filterable
                              placeholder="Select"
                              @change="handleNseQtyList"
                            >
                            <el-option
                              v-for="(item, index) in nse_client_list"
                                :key="index"
                                :label="item.username"
                                :value="index"
                            >
                            </el-option>                         
                            </el-select>
                          <!-- </el-form-item> -->
                         </el-col>
                        <table class="stock_setting_table">
                          <thead>
                            <th>Script</th>
                            <th v-if="!config.config_trade_equity_as_units_instead_of_lots">Max order lot</th>
                            <th v-if="!config.config_trade_equity_as_units_instead_of_lots">Max net lot</th>
                            <th v-if="config.config_trade_equity_as_units_instead_of_lots">Max order qty</th>
                            <th v-if="config.config_trade_equity_as_units_instead_of_lots">Max net qty</th>
                          </thead>
                          <tbody>
                            <tr v-for="(nse, index) in nse_per_script_qty_setting_list" :key="index" >
                            <td> {{nse.name}}</td>
                            <td>  
                              <el-input
                                v-model="nse_per_script_qty_setting_list[index].max_order_qty"
                                autocomplete="off"
                              ></el-input>
                            </td>
                            <td>   
                              <el-input
                                v-model="nse_per_script_qty_setting_list[index].max_net_qty"
                                autocomplete="off"
                              ></el-input>
                            </td>
                            </tr>
                          </tbody>

                        </table>
                      </el-collapse-item>
                    </el-collapse>
                  </el-col>
                </div>





               <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.equity_brokerage_type"
                  label="Equity Brokerage Type"
                  prop="equity_brokerage_type"
                  :label-width="formLabelWidth"
                >
                  <el-select
                    v-model="equity.equity_brokerage_type"
                    clearable
                    filterable
                    placeholder="Select"
                    @blur="handleBlurNSEequity_brokerage_type"
                  >
                    <el-option label="Per Crore Basics" value="per_core_basics">
                    </el-option>
                    <!-- <el-option label="Per Lot Basics" value="per_lot_basics">
                    </el-option> -->
                    <!-- <el-option label="Crud Oil" value="003"> </el-option> -->
                  </el-select>
                  <small v-if="error_equity_brokerage_type" style="color:red;">Required Equity Brokerage Type</small>

                </el-form-item>
              </el-col>
              <el-col v-if="equity.equity_brokerage_type" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.equity_brokerage"
                  label="Equity Brokerage"
                  prop="equity_brokerage"
                  :label-width="formLabelWidth"
                >
                  <el-input @blur="handleBlurNSEequity_brokerage"
                    v-model="equity.equity_brokerage"
                    autocomplete="off"
                  ></el-input>
                  <small v-if="error_equity_brokerage" style="color:red;">Required Equity Brokerage</small>

                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.equity_exposure_equity_type"
                  label="Exposure Equity Type"
                  prop="equity_exposure_equity_type"
                  :label-width="formLabelWidth"
                >
                  <el-select
                    v-model="equity.equity_exposure_equity_type"
                    clearable
                    filterable
                    placeholder="Select"
                    @blur="handleBlurNSEequity_exposure_equity_type"
                  >
                    <el-option
                      label="Per Turnover Basis"
                      value="per_turnover_basis"
                    >
                    </el-option>
                    <!-- <el-option label="Per Lot Basis" value="per_lot_basis">
                    </el-option> -->
                  </el-select>
                  <small v-if="error_equity_exposure_equity_type" style="color:red;">Required Exposure Equity Type</small>

                </el-form-item>
              </el-col>
              <div v-if="equity.equity_exposure_equity_type == 'per_turnover_basis'">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.equity_intraday_exposure_margin_equity"
                  label="Intraday Exposure/Margin Equity"
                  prop="equity_intraday_exposure_margin_equity"
                  :label-width="formLabelWidth"
                >
                  <el-input @blur="handleBlurNSEequity_intraday_exposure_margin_equity"
                    v-model="equity.equity_intraday_exposure_margin_equity"
                    autocomplete="off"
                  ></el-input>
                  <small v-if="error_equity_intraday_exposure_margin_equity" style="color:red;">Required Intraday Exposure/Margin Equity</small>

                </el-form-item>
                 <small style="text-align:left;color:#fff;">
                  Exposure auto calculates the margin money required for any new trade entry. Calculation : turnover of a trade devided by Exposure is required margin. eg. if gold having lotsize of 100 is trading @ 45000 and exposure is 200, (45000 X 100) / 200 = 22500 is required to initiate the trade.
                </small>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.equity_holding_exposure_margin_equity"
                  label="Holding Exposure/Margin Equity"
                  prop="equity_holding_exposure_margin_equity"
                  :label-width="formLabelWidth"
                >
                  <el-input @blur="handleBlurNSEequity_holding_exposure_margin_equity"
                    v-model="equity.equity_holding_exposure_margin_equity"
                    autocomplete="off"
                  ></el-input>
                  <small v-if="error_equity_holding_exposure_margin_equity" style="color:red;">Required Holding Exposure/Margin Equity</small>

                </el-form-item>
                 <small style="text-align:left;color:#fff;">
                  Holding Exposure auto calculates the margin money required to hold a position overnight for the next market working day. Calculation : turnover of a trade devided by Exposure is required margin. eg. if gold having lotsize of 100 is trading @ 45000 and holding exposure is 800, (45000 X 100) / 80 = 56250 is required to hold position overnight. System automatically checks at a given time around market closure to check and close all trades if margin(M2M) insufficient.
                </small>
              </el-col>
              </div>
              <div v-if="mcx.mcx_exposure_mcx_type == 'per_lot_basis'">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="left">
                  <h5>Orders to be away by points in each script MCX:</h5>
                </el-col>
                <el-col v-for="(mcx, index) in mcx_exposure_stock_list" :key="index" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :label="mcx.name"
                  >
                  <br>
                  <div style="text-align:left;color:#fff;" class="sub-title">Intraday Exposure Value</div>
                  <el-input v-model="mcx_exposure_stock_list[index].intraday_value"
                        autocomplete="off"
                        placeholder="Intraday value"
                      ></el-input>
                      <div style="text-align:left;color:#fff;" class="sub-title"> Holding Exposure Value</div>
                      <el-input
                        v-model="mcx_exposure_stock_list[index].holding_value"
                        autocomplete="off"
                        placeholder="Holding value"
                      ></el-input>
                  </el-form-item>
                </el-col>
              </div>
              </div>
              
            </el-row>
          </div>
        </el-card>
        <br>

         <!-- OPT Futures  -->
         <el-card class="user_add_wrapper" v-if="allow_opt_trade">
          <div>
            <el-row :gutter="20" class="trading-client">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                <h3>Index Options Futures:</h3>
              </el-col>
            </el-row>

            <el-row :gutter="20" class="trading-client">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_trading"
                  label=""
                  prop="opt_trading"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="opt.opt_trading">Index Options Futures Trading</el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item>
                  &nbsp;
                </el-form-item>
              </el-col>
              <div v-if="opt.opt_trading">
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.opt_btst_start_time"
                    label="BTST start time"
                    prop="opt_btst_start_time"
                    :label-width="formLabelWidth"
                  >
                    <span style="padding-left: 10px;"><vue-timepicker  v-model="opt.btst_start_time" ></vue-timepicker></span>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.opt_btst_end_time"
                    label="BTST end time"
                    prop="opt_btst_end_time"
                    :label-width="formLabelWidth"
                  >
                    <span style="padding-left: 10px;"><vue-timepicker  v-model="opt.btst_end_time" ></vue-timepicker></span>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_minimum_lot_size_per_single_trade_opt"
                  label="Minimum lot size required per single trade of OPT"
                  prop="opt_minimum_lot_size_per_single_trade_opt"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="opt.opt_minimum_lot_size_per_single_trade_opt"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_maximum_lot_size_per_single_trade_opt"
                  label="Maximum lot size allowed per single trade of OPT"
                  prop="opt_maximum_lot_size_per_single_trade_opt"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="opt.opt_maximum_lot_size_per_single_trade_opt"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="
                    formErrors.opt_maximum_lot_size_per_script_opt_actively_open_at_time
                  "
                  label="Maximum lot size allowed per script of OPT to be actively open at a time"
                  prop="opt_maximum_lot_size_per_script_opt_actively_open_at_time"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="
                      opt.opt_maximum_lot_size_per_script_opt_actively_open_at_time
                    "
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_size_all_commodity"
                  label="Max Size All OPT"
                  prop="max_size_all_commodity"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="opt.opt_size_all_commodity"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_brokerage_type"
                  label="OPT Brokerage Type"
                  prop="opt_brokerage_type"
                  :label-width="formLabelWidth"
                >
                  <el-select
                    v-model="opt.opt_brokerage_type"
                    clearable
                    filterable
                    placeholder="Select"
                  >
                    <el-option label="Per Crore Basis" value="per_core_basis"></el-option>
                    <el-option label="Per Lot Basis" value="per_lot_basis"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="opt.opt_brokerage_type == 'per_core_basis'" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_brokerage_per_crore"
                  label="OPT brokerage per crore"
                  prop="opt_brokerage_per_crore"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="opt.opt_brokerage_per_crore"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>


              <el-col v-if="opt.opt_brokerage_type == 'per_lot_basis'" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_brokerage_per_crore"
                  label="OPT brokerage per lot"
                  prop="opt_brokerage_per_lot"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="opt.opt_brokerage_per_lot"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_exposure_opt_type"
                  label="Exposure OPT Type"
                  prop="opt_exposure_opt_type"
                  :label-width="formLabelWidth"
                >
                  <el-select
                    v-model="opt.opt_exposure_opt_type"
                    clearable
                    filterable
                    placeholder="Select"
                  >
                    <el-option
                      label="Per Turnover Basis"
                      value="per_turnover_basis"
                    >
                    </el-option>
                    <!-- <el-option label="Per Lot Basis" value="per_lot_basis">
                    </el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>

             


              <div v-if="opt.opt_exposure_opt_type == 'per_turnover_basis'">
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_intraday_exposure_margin_opt"
                  label="Intraday Exposure/Margin OPT"
                  prop="opt_intraday_exposure_margin_opt"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="opt.opt_intraday_exposure_margin_opt"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <small style="text-align:left;color:#fff;">
                  Exposure auto calculates the margin money required for any new trade entry. Calculation : turnover of a trade devided by Exposure is required margin. eg. if gold having lotsize of 100 is trading @ 45000 and exposure is 200, (45000 X 100) / 200 = 22500 is required to initiate the trade.
                </small>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_holding_exposure_margin_opt"
                  label="Holding Exposure/Margin OPT"
                  prop="opt_holding_exposure_margin_opt"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="opt.opt_holding_exposure_margin_opt"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <small style="text-align:left;color:#fff;">
                  Holding Exposure auto calculates the margin money required to hold a position overnight for the next market working day. Calculation : turnover of a trade devided by Exposure is required margin. eg. if gold having lotsize of 100 is trading @ 45000 and holding exposure is 800, (45000 X 100) / 80 = 56250 is required to hold position overnight. System automatically checks at a given time around market closure to check and close all trades if margin(M2M) insufficient.
                </small>
              </el-col>
              </div>

              <!-- <div v-if="mcx.mcx_exposure_mcx_type == 'per_lot_basis'">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="left">
                  <h5>Orders to be away by points in each script MCX:</h5>
                </el-col>
                <el-col v-for="(mcx, index) in mcx_exposure_stock_list" :key="index" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :label="mcx.name"
                  >
                  <br>
                  <div style="text-align:left;color:#fff;" class="sub-title">Intraday Exposure Value</div>
                  <el-input v-model="mcx_exposure_stock_list[index].intraday_value"
                        autocomplete="off"
                        placeholder="Intraday value"
                      ></el-input>
                      <div style="text-align:left;color:#fff;" class="sub-title"> Holding Exposure Value</div>
                      <el-input
                        v-model="mcx_exposure_stock_list[index].holding_value"
                        autocomplete="off"
                        placeholder="Holding value"
                      ></el-input>
                  </el-form-item>
                </el-col>
              </div> -->


              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_sell_permission"
                  label=""
                  prop="opt_sell_permission"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="opt.opt_sell_permission">OPT Selling Permission</el-checkbox>
                </el-form-item>
              </el-col>

              
              <el-col v-if="opt.opt_sell_permission" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_brokerage_per_crore"
                  label="OPT Sell Margin"
                  prop="opt_sell_margin"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="opt.opt_sell_margin"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>

              </div>
            </el-row>
          </div>
        </el-card>
        <br>

          <!-- CRYPTO Futures  -->
        <el-card class="user_add_wrapper" v-if="allow_crypto_trade">
          <div>
            <el-row :gutter="20" class="trading-client">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                <h3>Index Crypto:</h3>
              </el-col>
            </el-row>

            <el-row :gutter="20" class="trading-client">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.crypto_trading"
                  label=""
                  prop="crypto_trading"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="crypto.crypto_trading">Index Crypto Trading</el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item>
                  &nbsp;
                </el-form-item>
              </el-col>
              <div v-if="crypto.crypto_trading">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.error_crypto_minimum_qty_size_per_single_trade"
                  label="Minimum qty size required per single trade of crypto"
                  prop="crypto_minimum_qty_size_per_single_trade"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="crypto.crypto_minimum_qty_size_per_single_trade"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.error_crypto_maximum_qty_size_per_single_trade"
                  label="Maximum qty size allowed per single trade of crypto"
                  prop="crypto_maximum_qty_size_per_single_trade"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="crypto.crypto_maximum_qty_size_per_single_trade"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="
                    formErrors.error_crypto_maximum_qty_size_per_script_actively_open_at_time"
                  label="Maximum lot size allowed per script of crypto to be actively open at a time"
                  prop="crypto_maximum_qty_size_per_script_actively_open_at_time"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="crypto.crypto_maximum_qty_size_per_script_actively_open_at_time"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.error_crypto_size_all_commodity"
                  label="Max Size All Crypto"
                  prop="max_size_all_commodity"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="crypto.crypto_size_all_commodity"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.error_crypto_brokerage_type"
                  label="Crypto Brokerage Type"
                  prop="crypto_brokerage_type"
                  :label-width="formLabelWidth"
                >
                  <el-select
                    v-model="crypto.crypto_brokerage_type"
                    clearable
                    filterable
                    placeholder="Select"
                  >
                    <el-option label="Per Crore Basis" value="per_core_basis"></el-option>
                    <el-option label="Per Qty Basis" value="per_qty_basis"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="crypto.crypto_brokerage_type == 'per_core_basis'" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_brokerage_per_crore"
                  label="Crypto brokerage per crore"
                  prop="crypto_brokerage_per_crore"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="crypto.crypto_brokerage_per_crore"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>


              <el-col v-if="crypto.crypto_brokerage_type == 'per_qty_basis'" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.crypto_brokerage_per_crore"
                  label="Crypto brokerage per qty"
                  prop="crypto_brokerage_per_qty"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="crypto.crypto_brokerage_per_qty"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.crypto_exposure_type"
                  label="Crypto Exposure Type"
                  prop="crypto_exposure_type"
                  :label-width="formLabelWidth"
                >
                  <el-select
                    v-model="crypto.crypto_exposure_type"
                    clearable
                    filterable
                    placeholder="Select"
                  >
                    <el-option
                      label="Per Turnover Basis"
                      value="per_turnover_basis"
                    >
                    </el-option>
                    <!-- <el-option label="Per Lot Basis" value="per_lot_basis">
                    </el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>

             


              <div v-if="crypto.crypto_exposure_type == 'per_turnover_basis'">
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.error_crypto_intraday_exposure_margin"
                  label="Intraday Exposure/Margin Crypto"
                  prop="Crypto_intraday_exposure_margin"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="crypto.crypto_intraday_exposure_margin"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <small style="text-align:left;color:#fff;">
                  Exposure auto calculates the margin money required for any new trade entry. Calculation : turnover of a trade devided by Exposure is required margin. eg. if gold having qty of 100 is trading @ 45000 and exposure is 200, (45000 X 100) / 200 = 22500 is required to initiate the trade.
                </small>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.crypto_holding_exposure_margin"
                  label="Holding Exposure/Margin Crypto"
                  prop="crypto_holding_exposure_margin"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="crypto.crypto_holding_exposure_margin"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <small style="text-align:left;color:#fff;">
                  Holding Exposure auto calculates the margin money required to hold a position overnight for the next market working day. Calculation : turnover of a trade devided by Exposure is required margin. eg. if gold having qty of 100 is trading @ 45000 and holding exposure is 800, (45000 X 100) / 80 = 56250 is required to hold position overnight. System automatically checks at a given time around market closure to check and close all trades if margin(M2M) insufficient.
                </small>
              </el-col>
              </div>
              </div>
            </el-row>
          </div>
        </el-card>
        <br>
          <!-- FOREX Futures  -->
        <el-card class="user_add_wrapper" v-if="allow_forex_trade">
          <div>
            <el-row :gutter="20" class="trading-client">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                <h3>Index Forex:</h3>
              </el-col>
            </el-row>

            <el-row :gutter="20" class="trading-client">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.forex_trading"
                  label=""
                  prop="forex_trading"
                  :label-width="formLabelWidth"
                >
                  <el-checkbox v-model="forex.forex_trading">Index Forex Trading</el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item>
                  &nbsp;
                </el-form-item>
              </el-col>
              <div v-if="forex.forex_trading">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.error_forex_minimum_qty_size_per_single_trade"
                  label="Minimum qty size required per single trade of forex"
                  prop="forex_minimum_qty_size_per_single_trade"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="forex.forex_minimum_qty_size_per_single_trade"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.error_forex_maximum_qty_size_per_single_trade"
                  label="Maximum qty size allowed per single trade of forex"
                  prop="forex_maximum_qty_size_per_single_trade"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="forex.forex_maximum_qty_size_per_single_trade"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="
                    formErrors.error_forex_maximum_qty_size_per_script_actively_open_at_time"
                  label="Maximum lot size allowed per script of forex to be actively open at a time"
                  prop="forex_maximum_qty_size_per_script_actively_open_at_time"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="forex.forex_maximum_qty_size_per_script_actively_open_at_time"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.error_forex_size_all_commodity"
                  label="Max Size All Forex"
                  prop="max_size_all_commodity"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="forex.forex_size_all_commodity"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.error_forex_brokerage_type"
                  label="Forex Brokerage Type"
                  prop="forex_brokerage_type"
                  :label-width="formLabelWidth"
                >
                  <el-select
                    v-model="forex.forex_brokerage_type"
                    clearable
                    filterable
                    placeholder="Select"
                  >
                    <el-option label="Per Crore Basis" value="per_core_basis"></el-option>
                    <el-option label="Per Qty Basis" value="per_qty_basis"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="forex.forex_brokerage_type == 'per_core_basis'" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.opt_brokerage_per_crore"
                  label="Forex brokerage per crore"
                  prop="forex_brokerage_per_crore"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="forex.forex_brokerage_per_crore"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>


              <el-col v-if="forex.forex_brokerage_type == 'per_qty_basis'" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.forex_brokerage_per_crore"
                  label="Forex brokerage per qty"
                  prop="forex_brokerage_per_qty"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="forex.forex_brokerage_per_qty"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.forex_exposure_type"
                  label="Forex Exposure Type"
                  prop="forex_exposure_type"
                  :label-width="formLabelWidth"
                >
                  <el-select
                    v-model="forex.forex_exposure_type"
                    clearable
                    filterable
                    placeholder="Select"
                  >
                    <el-option
                      label="Per Turnover Basis"
                      value="per_turnover_basis"
                    >
                    </el-option>
                    <!-- <el-option label="Per Lot Basis" value="per_lot_basis">
                    </el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>

             


              <div v-if="forex.forex_exposure_type == 'per_turnover_basis'">
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.error_forex_intraday_exposure_margin"
                  label="Intraday Exposure/Margin Forex"
                  prop="forex_intraday_exposure_margin"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="forex.forex_intraday_exposure_margin"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <small style="text-align:left;color:#fff;">
                  Exposure auto calculates the margin money required for any new trade entry. Calculation : turnover of a trade devided by Exposure is required margin. eg. if gold having qty of 100 is trading @ 45000 and exposure is 200, (45000 X 100) / 200 = 22500 is required to initiate the trade.
                </small>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.forex_holding_exposure_margin"
                  label="Holding Exposure/Margin Forex"
                  prop="forex_holding_exposure_margin"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="forex.forex_holding_exposure_margin"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <small style="text-align:left;color:#fff;">
                  Holding Exposure auto calculates the margin money required to hold a position overnight for the next market working day. Calculation : turnover of a trade devided by Exposure is required margin. eg. if gold having qty of 100 is trading @ 45000 and holding exposure is 800, (45000 X 100) / 80 = 56250 is required to hold position overnight. System automatically checks at a given time around market closure to check and close all trades if margin(M2M) insufficient.
                </small>
              </el-col>
              </div>
              </div>
            </el-row>
          </div>
        </el-card>
        <br>

       

        <!-- Other  -->
        <el-card class="user_add_wrapper">
        <div>
          <el-row :gutter="20" class="trading-client">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="left">
              <h3>Other:</h3>
            </el-col>
          </el-row>

          <el-row :gutter="20" class="trading-client">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item
                :error="formErrors.notes"
                label="Notes"
                prop="notes"
                :label-width="formLabelWidth"
              >
                <el-input
                  v-model="formData.notes"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col v-if="getRoleSlug === 'admin'" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item
                :error="formErrors.transaction_password"
                label="Transaction Password"
                prop="transaction_password"
                :label-width="formLabelWidth"
              >
                <el-input
                  v-model="formData.transaction_password"
                  type="password"
                  autocomplete="off"
                  show-password
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col v-if="getRoleSlug === 'broker'" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item
                :error="formErrors.transaction_password"
                label="Broker Password"
                prop="transaction_password"
                :label-width="formLabelWidth"
              >
                <!-- <el-input
                  v-model="formData.transaction_password"
                  autocomplete="off"
                ></el-input> -->
                <el-input
                    v-model="formData.transaction_password"
                    type="password"
                    autocomplete="off"
                    show-password
                  />
              </el-form-item>
            </el-col>
            <el-col
              v-if="getRoleSlug === 'superadmin'"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                :error="formErrors.admin"
                label="Admin"
                prop="admin"
                :label-width="formLabelWidth"
              >
                <el-select v-model="formData.admin" @change="handleBrokerList" placeholder="Select">
                  <el-option
                     v-for="item in listAdmins"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              v-if="getRoleSlug === 'admin' || getRoleSlug === 'superadmin'"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                :error="formErrors.broker_id"
                label="Broker"
                prop="broker_id"
                :label-width="formLabelWidth"
              >
                <el-select v-model="formData.broker_id" placeholder="Select">
                  <el-option
                    v-for="item in listDataDropdownBrokers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            >
            
              <el-form-item
                label="Block Script"
              >
                  <div class="block_button">
                  <el-button type="primary" @click="addScript">+</el-button>
                </div>
                             
              
            
              </el-form-item>
             
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="right">
           <el-button @click="handelCancel()">Cancel</el-button>
            &nbsp;
            <el-button type="info" @click="sendChildFormData()">Save</el-button>
            <!-- {{ userInfo.id }} -->
          </el-col>
          </el-row>
        </div>
        </el-card>
      </el-form>
    </div>

    <ScriptAddDialog
      :dialog-visible.sync="dialogVisibleScriptAddEdit"
      :dialog-type="dialogTypeScriptAddEdit"
      :form-data="formData"
      :mcxStockList="mcxStockListDataSet"
      :nseStockList="nseStockListDataSet"
      :optStockList="optStockListDataSet"
      :cryptoStockList="cryptoStockListDataSet"
      :forexStockList="forexStockListDataSet"
      :form-errors.sync="formErrors"
      :allow_mcx_trade="allow_mcx_trade"
      :allow_nse_trade="allow_nse_trade"
      :allow_opt_trade="allow_opt_trade"
      :allow_forex_trade="allow_forex_trade"
      :allow_crypto_trade="allow_crypto_trade"
      @getChildFormData="handleDuplicateAccount($event)"
      @childClose="handleDuplicateAccountClose()"
    />

  </div>
</template>




<script>
import { requiredRule } from "@/rules/all-rules";
import getRoleSlug from "@/store/app.js";
// import { edit } from "@/api/trading-client";
import { store, edit, update , clientsQtySettingList } from "@/api/trading-client";
import { fetchBrokersList } from "@/api/market-watch";
import { mapGetters } from "vuex";
import { fetchAdminAllowedStockList , fetchStockList} from "@/api/market-watch";
import ScriptAddDialog from "@/views/trading-clients/components/ScriptAddDialog.vue";
import { fetchAllAdminList, adminBrokers } from "@/api/super-admin/admin";
import VueTimepicker from 'vue2-timepicker'


export default {
  name: "AddEdit",
  components: {
    ScriptAddDialog,
    VueTimepicker
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      activeNames : 1,
      activeNamesNse : 1,
      formRules: {},
      formLabelWidth: "120px",
      formData: {
        id: "",
        broker: "",
        mcx_script: [],
        nse_script: [],
        opt_script: [],
        crypto_script: [],
        forex_script: []
      },
      listScripts: {},
      mcx_brokerage_lot_wise: {},
      mcx_exposure_stock_list: {},
      mcx_per_script_qty_setting_list:{},
      nse_per_script_qty_setting_list:{},
      mcx_client_list:[],
      nse_client_list:[],
      nseStockList: {},
      optStockList: {},
      cryptoStockList: {},
      forexStockList: {},
      config: {},
      mcx: {
        mcx_trading: false
      },
      equity: {
        equity_trading : false
      },
      opt: {
        opt_trading : false
      },
      crypto: {
        crypto_trading : false
      },
      forex: {
        forex_trading : false
      },
      listDataDropdownBrokers: {},
      dialogVisibleScriptAddEdit: false,
      dialogTypeScriptAddEdit: "create",
      mcxStockListDataSet: {},
      nseStockListDataSet: {},
      optStockListDataSet: {},
      cryptoStockListDataSet: {},
      forexStockListDataSet: {},
      listAdmins: {},
      error_equity_minimum_lot_size_per_single_trade_equity_index: false,
      error_equity_maximum_lot_size_per_single_trade_equity_index: false,
      error_equity_maximum_lot_size_per_script_equity_index_actively_open_at_time: false,
      error_equity_max_size_all_equity: false,
      error_equity_brokerage_type: false,
      error_equity_brokerage: false,
      error_equity_exposure_equity_type: false,
      error_equity_intraday_exposure_margin_equity: false,
      error_equity_holding_exposure_margin_equity: false,

      error_mcx_minimum_lot_size_per_single_trade_mcx:false,
      error_mcx_maximum_lot_size_per_single_trade_mcx:false,
      error_mcx_maximum_lot_size_per_script_mcx_actively_open_at_time:false,
      error_max_size_all_commodity:false,
      error_mcx_brokerage_type:false,
      error_mcx_brokerage_per_crore:false,
      error_mcx_intraday_exposure_margin_mcx:false,
      error_mcx_holding_exposure_margin_mcx:false,
      allow_mcx_trade: false,
      allow_nse_trade: false,
      allow_opt_trade: false,
      allow_forex_trade: false,
      allow_crypto_trade: false,

      validationDoneForMCX: false,
      validationDoneForNSE: false,
      validationDoneForCrypto: false,
      validationDoneForForex: false,
      submitType: '',
      inputDisabled: false
    };
  },
  computed: {
    ...mapGetters(["getRoleSlug", "userInfo"]),
    dialogTitle() {
      return this.dialogType === "create"
        ? "Add Pending Order"
        : "Edit Pending Order";
    },
  },
  created() {
    this.checkRole();
    this.id = this.$route.params.id;
    this.getBrokersList();
    console.log(this.$route.params.id);
    this.fetchData(this.$route.params.id);
    this.formRules.name = requiredRule("name");
    this.formRules.username = requiredRule("username");
    this.formRules.admin = requiredRule("admin");
    this.formRules.transaction_password = requiredRule("transaction_password");
    this.formRules.password = requiredRule("password");
    this.formRules.broker_id = requiredRule("broker_id");
    this.getScriptList()
    this.getAdminList()
    this.getMcxQtySettingClient()
    this.getNseQtySettingClient()

    if (this.getRoleSlug === 'superadmin') {
      this.allow_crypto_trade = this.allow_forex_trade = this.allow_opt_trade = this.allow_nse_trade = this.allow_mcx_trade = true
    }else{
      this.allow_crypto_trade = this.userInfo.allow_crypto_trade ?? false;
      this.allow_forex_trade = this.userInfo.allow_forex_trade ?? false;
      this.allow_opt_trade = this.userInfo.allow_opt_trade ?? false;
      this.allow_nse_trade = this.userInfo.allow_nse_trade ?? false;
      this.allow_mcx_trade = this.userInfo.allow_mcx_trade ?? false;
    }

    
  },
  methods: {
    addScript() {
        this.dialogVisibleScriptAddEdit = true;
        this.dialogTypeScriptAddEdit = "create";
        this.mcxStockListDataSet = this.mcx_brokerage_lot_wise;
        this.nseStockListDataSet = this.nseStockList;
        this.optStockListDataSet = this.optStockList;
        this.cryptoStockListDataSet = this.cryptoStockList;
        this.forexStockListDataSet = this.forexStockList;

        
        
    },
    handleDuplicateAccountClose() {
      this.dialogVisibleScriptAddEdit = false;
      this.delectval = null;
    },
    permission() {
      console.log('test')
    },
    handelCancel() {
      return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/trading-clients");
    },
    checkRole() {
      if (getRoleSlug != "admin") {
        this.formData.broker = this.userInfo.id;
      }
    },
    getBrokersList() {
      if (this.getRoleSlug == "admin") {
           fetchBrokersList().then((response) => {
          this.listDataDropdownBrokers = response.data.data;
        });
      }
   
    },
    mcxReset() {
      if(this.mcx.mcx_trading == false) {
        this.mcx = {
          mcx_trading: false
        }
      }
    },
    nseReset() {
      if(this.equity.equity_trading == false) {
        this.equity = {
          equity_trading: false
        }
      if(this.config.config_trade_equity_as_units_instead_of_lots){
        this.equity.equity_minimum_lot_size_per_single_trade_equity_index = 0;
        this.inputDisabled = true
      } else {
        this.equity.equity_minimum_lot_size_per_single_trade_equity_index = 1;
        this.inputDisabled = false
      }
      }
    },
    handleCheckedTradeEquityInsteedOfLots() {
      if(this.config.config_trade_equity_as_units_instead_of_lots){
        this.equity.equity_minimum_lot_size_per_single_trade_equity_index = 0;
        this.inputDisabled = true
      } else {
        this.equity.equity_minimum_lot_size_per_single_trade_equity_index = 1;
        this.inputDisabled = false
      }
    },

    // NSE
    handleBlurNSEequity_minimum_lot_size_per_single_trade_equity_index() {
      if(this.equity.equity_minimum_lot_size_per_single_trade_equity_index == null) {
        this.error_equity_minimum_lot_size_per_single_trade_equity_index = true;
      } else {
        this.error_equity_minimum_lot_size_per_single_trade_equity_index = false;

      }
    },
    handleBlurNSEequity_maximum_lot_size_per_single_trade_equity_index() {
      if(this.equity.equity_maximum_lot_size_per_single_trade_equity_index == null) {
        this.error_equity_maximum_lot_size_per_single_trade_equity_index = true;
      } else {
        this.error_equity_maximum_lot_size_per_single_trade_equity_index = false;
      }
    },
    handleBlurNSEequity_maximum_lot_size_per_script_equity_index_actively_open_at_time() {
      if(this.equity.equity_maximum_lot_size_per_script_equity_index_actively_open_at_time == null) {
        this.error_equity_maximum_lot_size_per_script_equity_index_actively_open_at_time = true;
      } else {
        this.error_equity_maximum_lot_size_per_script_equity_index_actively_open_at_time = false;
      }
    },
    handleBlurNSEequity_max_size_all_equity() {
      if(this.equity.equity_max_size_all_equity == null) {
        this.error_equity_max_size_all_equity = true;
      } else {
        this.error_equity_max_size_all_equity = false;
      }
    },
    handleBlurNSEequity_brokerage_type() {
      if(this.equity.equity_brokerage_type == null) {
        this.error_equity_brokerage_type = true;
      } else {
        this.error_equity_brokerage_type = false;
      }
    },
    handleBlurNSEequity_brokerage() {
      if(this.equity.equity_brokerage == null) {
        this.error_equity_brokerage = true;
      } else {
        this.error_equity_brokerage = false;
      }
    },
    handleBlurNSEequity_exposure_equity_type() {
      if(this.equity.equity_exposure_equity_type == null) {
        this.error_equity_exposure_equity_type = true;
      } else {
        this.error_equity_exposure_equity_type = false;
      }
    },
    handleBlurNSEequity_intraday_exposure_margin_equity() {
      if(this.equity.equity_intraday_exposure_margin_equity == null) {
        this.error_equity_intraday_exposure_margin_equity = true;
      } else {
        this.error_equity_intraday_exposure_margin_equity = false;
      }
    },
    handleBlurNSEequity_holding_exposure_margin_equity() {
      if(this.equity.equity_holding_exposure_margin_equity == null) {
        this.error_equity_holding_exposure_margin_equity = true;
      } else {
        this.error_equity_holding_exposure_margin_equity = false;
      }
    },

    // MCX 
    handleBlurMCXmcx_minimum_lot_size_per_single_trade_mcx() {
      if(this.mcx.mcx_minimum_lot_size_per_single_trade_mcx == null) {
        this.error_mcx_minimum_lot_size_per_single_trade_mcx = true;
      } else {
        this.error_mcx_minimum_lot_size_per_single_trade_mcx = false;
      }
    },
    handleBlurMCXmcx_maximum_lot_size_per_single_trade_mcx() {
      if(this.mcx.mcx_maximum_lot_size_per_single_trade_mcx == null) {
        this.error_mcx_maximum_lot_size_per_single_trade_mcx = true;
      } else {
        this.error_mcx_maximum_lot_size_per_single_trade_mcx = false;
      }
    },
    handleBlurMCXmcx_maximum_lot_size_per_script_mcx_actively_open_at_time() {
      if(this.mcx.mcx_maximum_lot_size_per_script_mcx_actively_open_at_time == null) {
        this.error_mcx_maximum_lot_size_per_script_mcx_actively_open_at_time = true;
      } else {
        this.error_mcx_maximum_lot_size_per_script_mcx_actively_open_at_time = false;
      }
    },
    handleBlurMCXmax_size_all_commodity() {
      if(this.mcx.max_size_all_commodity == null) {
        this.error_max_size_all_commodity = true;
      } else {
        this.error_max_size_all_commodity = false;
      }
    },
    handleBlurMCXmcx_brokerage_type() {
      if(this.mcx.mcx_brokerage_type == null) {
        this.error_mcx_brokerage_type = true;
      } else {
        this.error_mcx_brokerage_type = false;
      }
    },
    handleBlurMCXmcx_brokerage_per_crore() {
      if(this.mcx.mcx_brokerage_type == 'per_core_basis' && this.mcx.mcx_brokerage_per_crore == null  ) {
        this.error_mcx_brokerage_per_crore = true;
      } else {
        this.error_mcx_brokerage_per_crore = false;
      }
    },
    handleBlurMCXmcx_intraday_exposure_margin_mcx() {
      if(this.mcx.mcx_intraday_exposure_margin_mcx == null && this.mcx.mcx_exposure_mcx_type == 'per_turnover_basis') {
        this.error_mcx_intraday_exposure_margin_mcx = true;
      } else {
        this.error_mcx_intraday_exposure_margin_mcx = false;
      }
    },
    handleBlurMCXmcx_holding_exposure_margin_mcx() {
      if(this.mcx.mcx_holding_exposure_margin_mcx == null && this.mcx.mcx_exposure_mcx_type == 'per_turnover_basis') {
        this.error_mcx_holding_exposure_margin_mcx = true;
      } else {
        this.error_mcx_holding_exposure_margin_mcx = false;
      }
    },
  
    sendChildFormData() {
      console.log('this.mcx.mcx_trading',this.mcx.mcx_trading)
      console.log('this.equity.equity_trading',this.equity.equity_trading)
      if(this.mcx.mcx_trading == false && this.equity.equity_trading == false) {
        this.flashError('Please select MCX or Equity Trading option');
      }
    

      // validation check for mcx and nse

      if(this.mcx.mcx_trading && this.equity.equity_trading){ 
        if(this.mcx.mcx_minimum_lot_size_per_single_trade_mcx == null) {
          this.error_mcx_minimum_lot_size_per_single_trade_mcx = true;
          this.validationDoneForMCX = false
        } else if(this.mcx.mcx_maximum_lot_size_per_single_trade_mcx == null  && !this.config.mcx_per_script_qty_setting) {
          this.error_mcx_maximum_lot_size_per_single_trade_mcx = true;
          this.validationDoneForMCX = false
        } else if(this.mcx.mcx_maximum_lot_size_per_script_mcx_actively_open_at_time == null && !this.config.mcx_per_script_qty_setting) {
          this.error_mcx_maximum_lot_size_per_script_mcx_actively_open_at_time = true;
          this.validationDoneForMCX = false
        } else if(this.mcx.max_size_all_commodity == null) {
          this.error_max_size_all_commodity = true;
          this.validationDoneForMCX = false
        } else if(this.mcx.mcx_brokerage_type == null) {
          this.error_mcx_brokerage_type = true;
          this.validationDoneForMCX = false
        } else if(this.mcx.mcx_brokerage_type == 'per_core_basis' && this.mcx.mcx_brokerage_per_crore == null  ) {
          this.error_mcx_brokerage_per_crore = true;
          this.validationDoneForMCX = false
        } else if (this.mcx.mcx_intraday_exposure_margin_mcx == null && this.mcx.mcx_exposure_mcx_type == 'per_turnover_basis') {
          this.error_mcx_intraday_exposure_margin_mcx = true;
          this.validationDoneForMCX = false
        } else if(this.mcx.mcx_holding_exposure_margin_mcx == null) {
          this.error_mcx_holding_exposure_margin_mcx = true;
          this.validationDoneForMCX = false
        }else {
          this.validationDoneForMCX = true
        }

        if(this.equity.equity_minimum_lot_size_per_single_trade_equity_index == null) {
          this.error_equity_minimum_lot_size_per_single_trade_equity_index = true;
          this.validationDoneForNSE = false
        }else if(this.equity.equity_maximum_lot_size_per_single_trade_equity_index == null  && !this.config.nse_per_script_qty_setting) {
          this.error_equity_maximum_lot_size_per_single_trade_equity_index = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_maximum_lot_size_per_script_equity_index_actively_open_at_time == null  && !this.config.nse_per_script_qty_setting) {
          this.error_equity_maximum_lot_size_per_script_equity_index_actively_open_at_time = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_max_size_all_equity == null) {
          this.error_equity_max_size_all_equity = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_brokerage_type == null) {
          this.error_equity_brokerage_type = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_brokerage == null) {
          this.error_equity_brokerage = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_exposure_equity_type == null) {
          this.error_equity_exposure_equity_type = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_intraday_exposure_margin_equity == null) {
          this.error_equity_intraday_exposure_margin_equity = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_holding_exposure_margin_equity == null) {
          this.error_equity_holding_exposure_margin_equity = true;
          this.validationDoneForNSE = false
        } else {
          // this.submit()
          this.validationDoneForNSE = true
        }
      } else {
        console.log('both else')
      }



      // validation check for mcx 
      if(this.mcx.mcx_trading){ 
        if(this.mcx.mcx_minimum_lot_size_per_single_trade_mcx == null) {
          this.error_mcx_minimum_lot_size_per_single_trade_mcx = true;
          this.validationDoneForMCX = false
        } else if(this.mcx.mcx_maximum_lot_size_per_single_trade_mcx == null  && !this.config.mcx_per_script_qty_setting) {
          this.error_mcx_maximum_lot_size_per_single_trade_mcx = true;
          this.validationDoneForMCX = false
        } else if(this.mcx.mcx_maximum_lot_size_per_script_mcx_actively_open_at_time == null && !this.config.mcx_per_script_qty_setting) {
          this.error_mcx_maximum_lot_size_per_script_mcx_actively_open_at_time = true;
          this.validationDoneForMCX = false
        } else if(this.mcx.max_size_all_commodity == null) {
          this.error_max_size_all_commodity = true;
          this.validationDoneForMCX = false
        } else if(this.mcx.mcx_brokerage_type == null) {
          this.error_mcx_brokerage_type = true;
          this.validationDoneForMCX = false
        } else if(this.mcx.mcx_brokerage_type == 'per_core_basis' && this.mcx.mcx_brokerage_per_crore == null  ) {
          this.error_mcx_brokerage_per_crore = true;
          this.validationDoneForMCX = false
        } else if ( this.mcx.mcx_intraday_exposure_margin_mcx == null && this.mcx.mcx_exposure_mcx_type == 'per_turnover_basis') {
          this.error_mcx_intraday_exposure_margin_mcx = true;
          this.validationDoneForMCX = false
        } else if(this.mcx.mcx_holding_exposure_margin_mcx == null && this.mcx.mcx_exposure_mcx_type == 'per_turnover_basis') {
          this.error_mcx_holding_exposure_margin_mcx = true;
          this.validationDoneForMCX = false
        }else {
          // this.submit()
          this.validationDoneForMCX = true
        }
      } else {
        console.log('mcs else')
      }

      // validation check for nse 
      if(this.equity.equity_trading){
        if(this.equity.equity_minimum_lot_size_per_single_trade_equity_index == null) {
          this.error_equity_minimum_lot_size_per_single_trade_equity_index = true;
          this.validationDoneForNSE = false
        }else if(this.equity.equity_maximum_lot_size_per_single_trade_equity_index == null  && !this.config.nse_per_script_qty_setting) {
          this.error_equity_maximum_lot_size_per_single_trade_equity_index = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_maximum_lot_size_per_script_equity_index_actively_open_at_time == null && !this.config.nse_per_script_qty_setting) {
          this.error_equity_maximum_lot_size_per_script_equity_index_actively_open_at_time = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_max_size_all_equity == null) {
          this.error_equity_max_size_all_equity = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_brokerage_type == null) {
          this.error_equity_brokerage_type = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_brokerage == null) {
          this.error_equity_brokerage = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_exposure_equity_type == null) {
          this.error_equity_exposure_equity_type = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_intraday_exposure_margin_equity == null) {
          this.error_equity_intraday_exposure_margin_equity = true;
          this.validationDoneForNSE = false
        } else if(this.equity.equity_holding_exposure_margin_equity == null) {
          this.error_equity_holding_exposure_margin_equity = true;
          this.validationDoneForNSE = false
        } else {
          // this.submit()
          this.validationDoneForNSE = true
        }
      } else {
        console.log('nse else')
      }

      //crypto
      if(this.crypto.crypto_trading){
        if(this.crypto.crypto_minimum_qty_size_per_single_trade == null) {
          this.crypto_minimum_qty_size_per_single_trade = true;
          this.validationDoneForCrypto = false
        }else if(this.crypto.crypto_maximum_qty_size_per_single_trade == null) {
          this.error_crypto_maximum_qty_size_per_single_trade = true;
          this.validationDoneForCrypto = false
        } else if(this.crypto.crypto_maximum_qty_size_per_script_actively_open_at_time == null) {
          this.error_crypto_maximum_qty_size_per_script_actively_open_at_time = true;
          this.validationDoneForCrypto = false
        } else if(this.crypto.crypto_size_all_commodity == null) {
          this.error_crypto_size_all_commodity = true;
          this.validationDoneForCrypto = false
        } else if(this.crypto.crypto_brokerage_type == null) {
          this.error_crypto_brokerage_type = true;
          this.validationDoneForCrypto = false
        }  else if(this.crypto.crypto_exposure_type == null) {
          this.error_crypto_exposure_type = true;
          this.validationDoneForCrypto = false
        } else if(this.crypto.crypto_intraday_exposure_margin == null) {
          this.error_crypto_intraday_exposure_margin = true;
          this.validationDoneForCrypto = false
        } else if(this.crypto.crypto_holding_exposure_margin == null) {
          this.error_crypto_holding_exposure_margin = true;
          this.validationDoneForCrypto = false
        } else {
          // this.submit()
          this.validationDoneForCrypto = true
        }
      } else {
        console.log('nse else')
      }

       //forex
       if(this.forex.forex_trading){
        if(this.forex.forex_minimum_qty_size_per_single_trade == null) {
          this.forex_minimum_qty_size_per_single_trade = true;
          this.validationDoneForForex = false
        }else if(this.forex.forex_maximum_qty_size_per_single_trade == null) {
          this.error_forex_maximum_qty_size_per_single_trade = true;
          this.validationDoneForForex = false
        } else if(this.forex.forex_maximum_qty_size_per_script_actively_open_at_time == null) {
          this.error_forex_maximum_qty_size_per_script_actively_open_at_time = true;
          this.validationDoneForForex = false
        } else if(this.forex.forex_size_all_commodity == null) {
          this.error_forex_size_all_commodity = true;
          this.validationDoneForForex = false
        } else if(this.forex.forex_brokerage_type == null) {
          this.error_forex_brokerage_type = true;
          this.validationDoneForForex = false
        }  else if(this.forex.forex_exposure_type == null) {
          this.error_forex_exposure_type = true;
          this.validationDoneForForex = false
        } else if(this.forex.forex_intraday_exposure_margin == null) {
          this.error_forex_intraday_exposure_margin = true;
          this.validationDoneForForex = false
        } else if(this.forex.forex_holding_exposure_margin == null) {
          this.error_forex_holding_exposure_margin = true;
          this.validationDoneForForex = false
        } else {
          // this.submit()
          this.validationDoneForForex = true
        }
      } else {
        console.log('nse else')
      }

      console.log("validationDoneForNSE", this.validationDoneForNSE)
      console.log("validationDoneForMCX", this.validationDoneForMCX)
    //  form submit 
    if(this.validationDoneForMCX || this.validationDoneForNSE || this.validationDoneForCrypto ||  this.validationDoneForForex){
      this.submit()
    }
    
    //  if(this.validationDoneForMCX == true && this.validationDoneForNSE == true) {
    //     console.log('both submit')
    //     this.submit()
    //   } else if(this.validationDoneForMCX == true) {
    //     console.log('mcx submit')
    //     this.submit()
    //   } else if(this.validationDoneForNSE == true) {
    //     console.log('nse submit')
    //     this.submit()
    //   }if(this.validationDoneForCrypto == true){
    //     this.submit()
    //     return;
    //   } else {
    //     console.log('not hit')
    //     // this.submit()
    //   }
     
    },

    submit() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
            let mcxObj = {
              ...this.mcx,
              mcx_brokerage_lot_wise: this.filterBrokerageLot(this.mcx_brokerage_lot_wise),
              mcx_exposure_lot_wise: this.filterExposureLot(this.mcx_exposure_stock_list),
              mcx_per_script_qty_setting_list : this.filterMcxQtySetting(this.mcx_per_script_qty_setting_list)
              
            }
            let equityObj = {
             ...this.equity,
              nse_per_script_qty_setting_list : this.filterNseQtySetting(this.nse_per_script_qty_setting_list)
              
            }
            this.formData = Object.assign(this.formData, {
              config: this.config,
              mcx: mcxObj,
              equity: equityObj,
              opt: this.opt,
              crypto: this.crypto,
              forex: this.forex,
              status: true,
            });
            this.loading = false;
            if (this.formData.id != "" && this.formData.id) {
              update(this.formData)
                .then((response) => {
                  if (response.data.success === true) {
                    this.flashSuccess(response.data.message);
                    this.loading = false;
                    // this.$router.push(
                    //   "/" + getRoleSlug.state.roleSlug + "/trading-clients"
                    // );
                    if(getRoleSlug.state.roleSlug == 'superadmin') {
                      return this.$router.push("/super-admin/trading-clients");
                    } else {
                      return this.$router.push("/" + getRoleSlug.state.roleSlug + "/trading-clients");
                    }
                  // location.reload()

                  } else {
                    this.formErrors = this.validationErrors(
                      response.data.message
                    );
                    this.flashError(response.data.message);

                  }
                  this.loading = false;
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              store(this.formData)
                .then((response) => {
                  if (response.data.success === true) {
                    this.flashSuccess(response.data.message);
                    this.loading = false;
                    // this.$router.push(
                    //   "/" + getRoleSlug.state.roleSlug + "/trading-clients"
                    // );
                    if(getRoleSlug.state.roleSlug == 'superadmin') {
                      return this.$router.push("/super-admin/trading-clients");
                    } else {
                      return this.$router.push("/" + getRoleSlug.state.roleSlug + "/trading-clients");
                    }
                  // location.reload()

                  } else {
                    this.flashError(response.data.message);
                    this.formErrors = this.validationErrors(
                      response.data.message
                    );
                    this.flashError(response.data.message);

                  }
                  this.loading = false;
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        });
    },
   
    ///////////////////
    //// EDIT
    ///////////////////
    fetchData(id){
      if(id) {
        this.fetchEditData(id)
      }
    },
    // fetch by id
    fetchEditData(id) {
      // this.loading = true;
      edit(id)
        .then((response) => {
          this.formData = response.data.data;
          if (response.data.success === true) {
            this.formData = response.data.data;
            this.config = response.data.data.config;
            this.equity = response.data.data.equity;
            this.mcx = response.data.data.mcx;
            this.opt = response.data.data.opt;
            // this.loading = false;
          } else {
            this.flashError(response.data.message);
            this.$message.warning(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = true;
    },
    getMcxQtySettingClient() {
      // this.loading = true;
      console.log("call")
      let obj = {
        type:"MCX"
      }
      clientsQtySettingList(obj)
        .then((response) => {
          if (response.data.success === true) {
            this.mcx_client_list = response.data.data;
          } else {
            this.flashError(response.data.message);
            this.$message.warning(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNseQtySettingClient() {
      // this.loading = true;
      console.log("call")
      let obj = {
        type:"NSE"
      }
      clientsQtySettingList(obj)
        .then((response) => {
          if (response.data.success === true) {
            this.nse_client_list = response.data.data;
          } else {
            this.flashError(response.data.message);
            this.$message.warning(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterBrokerageLot(data) {
      let dataPost = []
      for(let i=0; i < data.length; i++) {
        if(data[i].value) {
          dataPost.push({
            name: data[i].name,
            value: data[i].value,
          })
        }
      }
      return dataPost;
    },
    filterExposureLot(data) {
      let dataPost = []
      console.log('       ..........................',data)
      for(let i=0; i < data.length; i++) {
        //if(data[i].intraday_value ) {
          dataPost.push({
            name: data[i].name,
            intraday_value: data[i].intraday_value,
            holding_value: data[i].holding_value
          })
        //}
      }
      return dataPost;
    },
    filterMcxQtySetting(data) {
      let dataPost = []
      console.log('       ..........................',data)
      for(let i=0; i < data.length; i++) {
        //if(data[i].intraday_value ) {
          dataPost.push({
            name: data[i].name,
            max_net_lot: data[i].max_net_lot,
            max_order_lot: data[i].max_order_lot
          })
        //}
      }
      return dataPost;
    },
    filterNseQtySetting(data) {
      let dataPost = []
      console.log('       ..........................',data)
      for(let i=0; i < data.length; i++) {
          dataPost.push({
            name: data[i].name,
            max_net_qty: data[i].max_net_qty,
            max_order_qty: data[i].max_order_qty
          })
      }
      return dataPost;
    },
    getScriptList() {
      let dataPost = {
        adminId : this.formData ? this.formData.admin : null
      }
      if (this.getRoleSlug === 'broker') {
        fetchStockList().then((response) => {
          this.getScriptCore(response)
        });
      } else {
        fetchAdminAllowedStockList(dataPost).then((response) => {
          this.listScripts  = response.data.data.all;
          let tempt = [];
          let temptExposureStockList = [];
          let mcxPerScriptQtySettingList = [];
          let nsePerScriptQtySettingList = [];
          // this.mcxStockList  = response.data.data.mcxStockList;
          for(let i = 0; i < response.data.data.mcxStockList.length; i++) {
            tempt.push({
              name: response.data.data.mcxStockList[i].name,
              id: response.data.data.mcxStockList[i].id,
              stock_id: response.data.data.mcxStockList[i].stock_id,
              value: '1000'
            })
          }
          for(let i = 0; i < response.data.data.mcxStockList.length; i++) {
            temptExposureStockList.push({
              name: response.data.data.mcxStockList[i].name,
              id: response.data.data.mcxStockList[i].id,
              stock_id: response.data.data.mcxStockList[i].stock_id,
              intraday_value: 1000,
              holding_value: 1000
            })
          }
          for(let i = 0; i < response.data.data.mcxUniqueStockList.length; i++) {
            mcxPerScriptQtySettingList.push({
              name: response.data.data.mcxUniqueStockList[i].name,
              id: response.data.data.mcxStockList[i].id,
              stock_id: response.data.data.mcxStockList[i].stock_id,
              max_order_lot: 1,
              max_net_lot: 1
            })
          }
          
          for(let i = 0; i < response.data.data.nseUniqueStockList.length; i++) {
            nsePerScriptQtySettingList.push({
              name: response.data.data.nseUniqueStockList[i].name,
              id: response.data.data.nseUniqueStockList[i].id,
              stock_id: response.data.data.nseUniqueStockList[i].stock_id,
              max_order_qty: 1, //response.data.data.nseUniqueStockList[i].lot_size,
              max_net_qty:  5, //response.data.data.nseUniqueStockList[i].lot_size,
            })
          }
          this.mcx_brokerage_lot_wise = tempt;
          this.mcx_exposure_stock_list = temptExposureStockList;
          this.nseStockList  = response.data.data.nseStockList;
          this.optStockList  = response.data.data.optStockList;
          this.cryptoStockList  = response.data.data.cryptoStockList;
          this.forexStockList  = response.data.data.forexStockList;
          this.mcx_per_script_qty_setting_list = mcxPerScriptQtySettingList;
          this.nse_per_script_qty_setting_list = nsePerScriptQtySettingList;


          for(let i =0; i< this.mcx_exposure_stock_list.length ; i++){
          this.formData.mcx_script.push(this.mcx_exposure_stock_list[i].stock_id)
          }
          for(let i =0; i< this.nseStockList.length ; i++){
            this.formData.nse_script.push(this.nseStockList[i].stock_id)
          }
          for(let i =0; i< this.optStockList.length ; i++){
            this.formData.opt_script.push(this.optStockList[i].stock_id)
          }
          for(let i =0; i< this.cryptoStockList.length ; i++){
            this.formData.crypto_script.push(this.cryptoStockList[i].stock_id)
          }
          for(let i =0; i< this.forexStockList.length ; i++){
            this.formData.forex_script.push(this.forexStockList[i].stock_id)
          }
          console.log(this.listScripts)
        });
      }
    },
    getScriptCore(response) {
      this.listScripts  = response.data.data.all;
      let tempt = [];
      let temptExposureStockList = [];
      let mcxPerScriptQtySettingList = [];
      let nsePerScriptQtySettingList = [];
      // this.mcxStockList  = response.data.data.mcxStockList;
      for(let i = 0; i < response.data.data.mcxStockList.length; i++) {
        tempt.push({
          name: response.data.data.mcxStockList[i].name,
          id: response.data.data.mcxStockList[i].id,
          stock_id: response.data.data.mcxStockList[i].stock_id,
          value: '1000'
        })
      }
      for(let i = 0; i < response.data.data.mcxStockList.length; i++) {
        temptExposureStockList.push({
          name: response.data.data.mcxStockList[i].name,
          id: response.data.data.mcxStockList[i].id,
          stock_id: response.data.data.mcxStockList[i].stock_id,
          intraday_value: 1000,
          holding_value: 1000
        })
      }
      for(let i = 0; i < response.data.data.mcxUniqueStockList.length; i++) {
            mcxPerScriptQtySettingList.push({
              name: response.data.data.mcxUniqueStockList[i].name,
              id: response.data.data.mcxStockList[i].id,
              stock_id: response.data.data.mcxStockList[i].stock_id,
              max_order_lot: 1,
              max_net_lot: 1
            })
          }
          
          for(let i = 0; i < response.data.data.nseUniqueStockList.length; i++) {
            nsePerScriptQtySettingList.push({
              name: response.data.data.nseUniqueStockList[i].name,
              id: response.data.data.nseUniqueStockList[i].id,
              stock_id: response.data.data.nseUniqueStockList[i].stock_id,
              max_order_qty: 1, //response.data.data.nseUniqueStockList[i].lot_size,
              max_net_qty:  5, //response.data.data.nseUniqueStockList[i].lot_size,
            })
          }
        this.mcxStockList = tempt;
        this.mcx_brokerage_lot_wise = tempt;
        this.mcx_exposure_stock_list = temptExposureStockList;
        this.nseStockList  = response.data.data.nseStockList;
        this.optStockList  = response.data.data.optStockList;
        this.cryptoStockList  = response.data.data.cryptoStockList;
        this.forexStockList  = response.data.data.forexStockList;
        this.mcx_per_script_qty_setting_list = mcxPerScriptQtySettingList;
        this.nse_per_script_qty_setting_list = nsePerScriptQtySettingList;
      
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
      });
    },
    appliesToAllBrokerageMCX() {
      let x = this.mcx_brokerage_lot_wise[0].value;
      
      for(let i=0; this.mcx_brokerage_lot_wise.length; i++){
        this.mcx_brokerage_lot_wise[i].value = x;
      }

    },
    appliesToAllExposureMCX() {
      let x = this.mcx_exposure_stock_list[0].intraday_value;
      let y = this.mcx_exposure_stock_list[0].holding_value;
      
      for(let i=0; this.mcx_exposure_stock_list.length; i++){
        this.mcx_exposure_stock_list[i].intraday_value = x;
        this.mcx_exposure_stock_list[i].holding_value = y;
      }

    },
    handleBrokerList(id) {
      this.formData.broker = ''
      adminBrokers(id)
        .then((response) => {
          if (response.data.success === true) {
            this.listDataDropdownBrokers = response.data.data;
          } else {
            this.flashError(response.data.message);
            this.$message.warning(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = true;
      this.getScriptList();
    },
    handleMcxQtyList(index) {
      let mcxQtyList = this.mcx_client_list[index].mcx_per_script_qty_setting_list;
      this.mcx_per_script_qty_setting_list = this.mcx_per_script_qty_setting_list.map(oldComment => {
          const newComment = mcxQtyList.find(newComment => newComment.name === oldComment.name);
          return newComment ? { ...oldComment, ...newComment } : oldComment;
      });
    },
    handleNseQtyList(index) {
      let nseQtyList = this.nse_client_list[index].nse_per_script_qty_setting_list;
      this.nse_per_script_qty_setting_list = this.nse_per_script_qty_setting_list.map(oldComment => {
          const newComment = nseQtyList.find(newComment => newComment.name === oldComment.name);
          return newComment ? { ...oldComment, ...newComment } : oldComment;
      });
    },
  },
};
</script>


